import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Axios from "../../../axios";
import { LoadingSpinner } from "../../Loading";
import toast from "react-hot-toast";
import Input from "../../input/input";
import PrimaryButton from "../../Button1/primarybutton";
import InputWithLabel from "../../input/inputWithLabel";
import { REGX } from "../../../content/regx";
import { PasswordValid } from "../../register/PasswordValid";

export const ShopifyAuth = () => {

  const [searchParams] = useSearchParams();

  let status  = false

  const [ONLoadState, SetOnloadState] = useState(false);

  const [ error, setError ] = useState(null)

  const [userinfo, setUserInfo] = useState({})

  const [resUrlParams, setResUrlParams] = useState("");


  const [passwordCheckList, setPasswordCheckList] = useState({
    charNumberValid: false,
    lowercaseValid: false,
    numberValid: false,
    specialCharValid: false,
    uppercaseValid: false
  })

  const navigate = useNavigate();

  useEffect(() => { if(!status)Check() }, []);

  const Check = async () => {

    try {

      status = true;
      SetOnloadState(true)

      const params = Object.fromEntries(searchParams);

      const urlParams = Object.keys(params).map((keys) => `${keys}=${params[keys]}`).join("&");

      const res = await Axios.post("/shopify/connetion/create?" + urlParams);

      console.log(res);

      setUserInfo(res.data.data)

      // setStatus(res.data.status);

      setResUrlParams(urlParams);

      SetOnloadState(false)

    } catch (Err) {
      setError("Failed To Register your Store, Please our support team for info.")
      toast.error("Failed to Integrate with your shopify store")

      SetOnloadState(false)

    }

  };

  // if (status) navigate("/login?" + resUrlParams);

  const onPasswordChange = (val, type = 'password') => {

    let temp = { ...userinfo };

    temp[type] = val;

    setUserInfo(temp)

    setPasswordCheckList({
      uppercaseValid: REGX.upperCase.test(val) ? true : false,
      lowercaseValid: REGX.lowerCase.test(val) ? true : false,
      charNumberValid: val.length >= 8 ? true : false,
      numberValid: REGX.number.test(val) ? true : false,
      specialCharValid: REGX.specialCharacters.test(val) ? true : false,
    })
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">

      {ONLoadState ? <LoadingSpinner className="my-auto h-full" /> : <>

      {/* Banner Img */}

      <div className="w-1/2 h-full bg-slate-400" >

        <img src="/Shopify.png" className="w-full h-full object-cover" alt="Banner" />

      </div>

      {/* Main section */}
      <div className="w-1/2 p-4 my-auto space-y-3 " >

        <h4 className="font-semibold " >Welcome To Engage</h4>

        <h4 className="font-semibold " >Shopify Store Connection</h4>

        <p className="text-red-500" >{error}</p>

        {

          !userinfo?.isAlreadyExist ?
            <>

              <InputWithLabel label="Email" labelTextWeight="text-14px font-medium" className="bg-slate-200" value={userinfo?.email ?? "example@gmail.com"} disabledinput={true} type="email" Placeholder="example@gmail.com" />

              <InputWithLabel label="password" labelTextWeight="text-14px font-medium" type="password" Placeholder="enter password" onchange={(e) => { onPasswordChange(e.target.value, "password") }} />

              {userinfo?.password?.length > 0 && <PasswordValid {...passwordCheckList} ></PasswordValid>}

              <InputWithLabel label="confirm password" labelTextWeight="text-14px font-medium" type="password" Placeholder="confirm password" onchange={(e) => { onPasswordChange(e.target.value, "confirm_password") }} />

              {userinfo?.confirm_password?.length > 0 && <PasswordValid {...passwordCheckList} ></PasswordValid>}

              <PrimaryButton text={"submit"} width="w-full" disabled={error ? true : false } />
            </> :

            <p className="font-medium text-14px w-[90%]">Your Account migrate Sccessfully, ( Please login with this Email : {userinfo?.email ?? "example@gmail.com"} ),<a href="/login" className="text-primary underline" > Click Here to Login</a></p>
        }

      </div>

      </> 
      }
    </div>
  );
};
