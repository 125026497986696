import React, { useEffect, useState } from "react";
import DefaultDropMenu from "../../input/droplist";
import BorderedButton from "../../Button1/BorderedButton";
import { Colors } from "chart.js";
import PopupFrame from "../../popup/popupFrame";
import PrimaryButton from "../../Button1/primarybutton";
import SwicthInput from "../../input/swicth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { constant } from "lodash";
import Axios from "../../../axios";
import axios from "axios";

export const Shopify = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="h-[85%] w-full p-5 select-none fade-in bg-white   px-8 space-y-3 rounded-xl py-8  ">
        <div className="flex  items-center justify-between gap-x-sm">
          <p className="text-lg font-bold "> Shopify </p>

          <SwicthInput
            label=""
            status={toggle}
            onchange={() => setToggle(!toggle)}
          />
        </div>
      </div>
    </>
  );
};

