import React from "react";
import PrimaryButton from "../../../Button1/primarybutton";
import { LastModified } from "../../../../utils/dateconvetor";

export const EmbededDomain = ({
  ListDomain = [],
  DeleteUSerSite = null,
  setshowScriptCode = null,
  setShow,
}) => {
  return (
    <>
      <div className="relative flex w-full bg- h-auto justify-between">
        <p className="w-fit h-auto text-lg font-semibold"></p>
        <div className="w-auto flex space-x-4  ">
          <PrimaryButton
            text={"Get Script Code"}
            style={{
              background: "white",
              color: "#2563eb",
              borderColor: "#2563eb",
            }}
            OnClick={() => {
              setshowScriptCode(true);
            }}
          />

          <PrimaryButton
            icon={
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="mr-2"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
              </svg>
            }
            text="Add Domain"
            OnClick={() => setShow(true)}
          />
        </div>
      </div>

      <div className="w-full  h-full space-y-3 overflow-y-auto">
        {ListDomain.length > 0 &&
          ListDomain.map((list) => {
            return (
              <div className="flex transition-all duration-500 justify-between py-3 px-5 border-1 bg-white hover:shadow-md rounded-xl">
                <div className="flex space-x-4">
                  <span className="flex my-auto">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      class="w-5 h-5"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
                    </svg>
                  </span>
                  <div>
                    <p className="my-auto font-medium text-primary">
                      {list.domain}
                    </p>
                    <p className="text-sm text-black">
                      {LastModified(list.created_at)}
                    </p>
                  </div>
                </div>
                {list.is_shopify_site ? (
                  <PrimaryButton
                    text={"Connected"}
                    style={{
                      background: "white",
                      color: "green",
                      borderColor: "green",
                    }}
                    OnClick={() => DeleteUSerSite(list.site_id)}
                  />
                ) : (
                  <PrimaryButton
                    text={"Remove"}
                    style={{
                      background: "white",
                      color: "red",
                      borderColor: "red",
                    }}
                    OnClick={() => DeleteUSerSite(list.site_id)}
                  />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
