import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../pages";
import { LoadingSpinner } from "../../Loading";
import { ActivateShopifyUserPLan } from "../../../actions/Billing";

export const ShopifyBillingSuccess = () => {


      const [searchParams] = useSearchParams();

      const nav = useNavigate()
    
useEffect( () => { FetchData() }, [] )
    const FetchData  = async () => {
        
        try{

            const charge_id = searchParams.get("charge_id");

            const result = await ActivateShopifyUserPLan(charge_id);
 
            toast.success("plan Activated, please check your billing");

            nav('/settings');

        }catch(err){
            console.log(err);
            toast.error("Failed To Activate The Plan, Please Contact For Help.")
        }

    }

    return(
        <Layout sidebarView={true}  >

            <div className="flex flex-col w-fit  m-auto gap-4 justify-center">

                <p className=" font-medium text-[16px] flex text-center mx-auto" >Thank you for purchasing Engage.</p>

                <LoadingSpinner text="Plan Activation Process Ongoing, Please Wait" />

            </div>

        </Layout>
    )

}