import React, { useEffect, useRef, useState } from "react";
import { Menu_postion_setup } from "../../utils/mouseEvent";

const DefaultDropMenu = ({
  id = "",
  mainclass = "",
  active = 0,
  fieldType = "",
  IndexValue = 0,
  edge = "",
  list = ["left", "right"],
  name = "",
  label = "Label name",
  labelTextsize = "",
  labelTextWeight = "",
  type = "text",
  Placeholder = "",
  fontweight = "",
  margin = "mb-0",
  spaceY = "space-y-2",
  padding = "",
  textvalue = null,
  disabledinput = false,
  width = "w-full",
  onchange = null,
  IsRule = false,
  ConditionKey = "",
  SubKey = "",
  height = "h-10",
  SelectIndex = false,
  childclass = "",
  top = "top-[10px]",
  iscapitalize = "capitalize",
  lableColor = "#000000",
  ...prop
}) => {
  const menuref = useRef();

  const [selectoption, setSelectoption] = useState(list[active]);

  const [DropMenuState, setDropMenuState] = useState(false);
  const [widthpop, setWidthpop] = useState({ zIndex: "99999" });

  const [optionPostion, setOptionPostion] = useState(" top-10 border-t-0 ");

  useEffect(() => {
    // if( DropMenuState ) console.log(menuref.current,"i32u6487236")
  }, [DropMenuState]);

  const triggerClick = (state) => {
    setSelectoption(state);
    setDropMenuState(false);
    if (edge == "") onchange(state, fieldType);
    else onchange(state, fieldType, IndexValue, edge);
  };
  const defaultOnCange = (index) => {
    setSelectoption(list[index]);
    setDropMenuState(false);
    onchange(index);
  };

  const RuleOnCange = (state) => {
    setSelectoption(state);
    setDropMenuState(false);
    if (fieldType !== "DefaultAction")
      onchange(fieldType, ConditionKey, { key: SubKey, value: state });
    else onchange(fieldType, ConditionKey, state);
  };

  // const postion_setup = ( event ) => {

  //     const winHeight = window.innerHeight;

  //     const winWidth = window.innerWidth;

  //     const blockHeight = event.target.offsetHeight;

  //     const blockWidth = event.target.offsetWidth;

  //     const mouse_p_Height = event.clientY;

  //     const per = (mouse_p_Height / winHeight) * 100;

  //     const balance = winHeight - mouse_p_Height;

  //     const { y, x } = event.target.getBoundingClientRect();

  //     console.log({ zIndex: '99999', width: `${event.target.offsetWidth}px`, top: `${(blockHeight+2) + y}px`,  right: `${winWidth - x -blockWidth}px` });

  //     if ((balance < ( blockHeight + 260 )) || (per > 78)) setWidthpop({ zIndex: '99999', width: `${event.target.offsetWidth}px`, bottom: `${(winHeight+10) - y}px`, right: `${winWidth - x -blockWidth}px` })

  //     else setWidthpop({ zIndex: '99999', width: `${event.target.offsetWidth}px`, top: `${(blockHeight+2) + y}px`,  right: `${winWidth - x -blockWidth}px` })

  // }

  useEffect(() => {
    setSelectoption(list[active]);
  }, [active, list]);

  useEffect(() => {
    let handleAction = (event) => {
      if (menuref.current && !menuref.current?.contains(event.target)) {
        // alert("test-out")
        setDropMenuState(false);
      }
    };

    document.addEventListener("mousedown", handleAction);
  }, []);
  return (
    <div className={`${spaceY} ${mainclass}`}>
      {label && (
        <label
          className={` ${labelTextsize} ${labelTextWeight} capitalize`}
          style={{ color: lableColor }}
        >
          {label}
        </label>
      )}

      <div className={`${childclass}  relative`}>
        <input
          type={type}
          className={`${iscapitalize}  caret-transparent ${height} block ${padding} text-14px text-black appearance-none bg-white  ${
            DropMenuState ? "rounded-b-none" : ""
          }  focus:border-primary  placeholder-gray-600 border rounded-8px pr-10 ${width} px-14px leading-5 placeholder-opacity-40 ${margin} cursor-pointer border-secondar`}
          id={id}
          name={name}
          placeholder={Placeholder}
          disabled={disabledinput}
          onClick={(e) => {
            setDropMenuState(true);
            Menu_postion_setup(e, setWidthpop);
          }}
          value={selectoption}
        />

        <span className={`absolute ${top} right-3 inline-block`}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2097_16399)">
              <path
                d="M7.98417 10.7345L10.3583 13.1087C10.7158 13.4662 11.2933 13.4662 11.6508 13.1087L14.025 10.7345C14.6025 10.157 14.19 9.16699 13.3742 9.16699H8.62584C7.81001 9.16699 7.40667 10.157 7.98417 10.7345Z"
                fill="#586474"
              />
            </g>
            <defs>
              <clipPath id="clip0_2097_16399">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>

        {DropMenuState && (
          <div
            className={`fixed shadow-md list-industry  border-1  overflow-x-hidden bg-white rounded-b-md  max-h-[200px] overflow-y-auto`}
            style={widthpop}
            ref={menuref}
          >
            {/* {ref={menuref}} */}
            {list.map((list, index) => {
              return (
                <p
                  key={index}
                  className={`w-full overflow-hidden noselect px-4 py-2 ${iscapitalize} text-base hover:bg-gray-100 cursor-pointer whitespace-nowrap text-ellipsis`}
                  onClick={() => {
                    SelectIndex
                      ? defaultOnCange(index)
                      : IsRule
                      ? RuleOnCange(list)
                      : triggerClick(list);
                  }}
                >
                  {list}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default DefaultDropMenu;

{
  /* <div className="fixed bg-red-500 w-full top-[213px]" >6435</div> */
}
