import React, { useState } from 'react'
import PrimaryButton from '../../../Button1/primarybutton'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { createShopifyUserPLan } from '../../../../actions/Billing';

const PlanManth = [
  {
    header: "free",
    amount: 0.00,
    data: [
      "200 Views Per Month",
      "1 Site",
      "Unlimited Campaigns",
      "1 Workspace",
      "100 MB storage",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheels",
      "Games",
      "1 User",
      "50 AI Questions/Month",
      "Standard support",
    ]
  },
  {
    header: "starter",
    amount: 9.00,
    data: [
      "5000 Views Per Month",
      "3 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "3 Workspace",
      "1GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "2 User",
      "100 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Priority Support",
    ]
  },
  {
    header: "premium",
    amount: 24.00,
    data: [
      "10000 Views Per Month",
      "5 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "5 Workspace",
      "2GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "3 User",
      "500 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Priority Support",
    ]
  },
  {
    header: "professional",
    amount: 49.00,
    data: [
      "25000 Views Per Month",
      "10 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "8 Workspace",
      "3GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "Calculator",
      "5 User",
      "1000 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Live Chat Support",
      "Priority Support"
    ]
  },
  {
    header: "advanced",
    amount: 99.00,
    data: [
      "Unlimited Views Per Month",
      "15 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "10 Workspace",
      "5GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "Calculator",
      "10 User",
      "2000 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Live Chat Support",
      "Priority Support"
    ]
  },
]



const PlanYear = [
  {
    header: "Free",
    amount: 0.00,
    data: [
      "200 Views Per Month",
      "1 Site",
      "Unlimited Campaigns",
      "1 Workspace",
      "100 MB storage",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheels",
      "Games",
      "1 User",
      "50 AI Questions/Month",
      "Standard support",
    ]
  },
  {
    header: "starter",
    amount: 7.2,
    data: [
      "5000 Views Per Month",
      "3 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "3 Workspace",
      "1GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "2 User",
      "100 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Priority Support",
    ]
  },
  {
    header: "premium",
    amount: 19.2,
    data: [
      "10000 Views Per Month",
      "5 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "5 Workspace",
      "2GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "3 User",
      "500 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Priority Support",
    ]
  },
  {
    header: "professional",
    amount: 39.2,
    data: [
      "25000 Views Per Month",
      "10 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "8 Workspace",
      "3GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "Calculator",
      "5 User",
      "1000 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Live Chat Support",
      "Priority Support"
    ]
  },
  {
    header: "advanced",
    amount: 79.2,
    data: [
      "Unlimited Views Per Month",
      "15 Sites",
      "$9 For Every Additional Site",
      "Unlimited Campaigns",
      "10 Workspace",
      "5GB File Upload",
      "Unlimited Subscribers",
      "Limited Interactive Content",
      "Quizzes",
      "Spin Wheel",
      "Games",
      "Ecommerce",
      "Poll",
      "Calculator",
      "10 User",
      "2000 AI Questions/Month",
      "Remove GoZen Branding",
      "Integrations",
      "Live Chat Support",
      "Priority Support"
    ]
  },
]


const PlanDuration = [
  {
    name: "monthly"
  },
  {
    name: "yearly"
  }
]
export const Planslist = ({ setCheckout = null, }) => {

  const [plantype, setPlanType] = useState('monthly');

  const Navigate = useNavigate();
  const currentPLanType = plantype === 'monthly' ? PlanManth : PlanYear

  const { userPlan, userDetail } = useSelector(state => ({ userPlan: state?.userPlan, userDetail: state?.userDetail }));

  const trigger = async (isThisCurrentPLan, plan) => {

    try {


      if (userDetail?.engage_gateway == "shopify") {
        const data = await createShopifyUserPLan(plan.header, plantype);
        window.location.href = data.data.confirmation_url;
        toast.success("ready for pay"); return;
      }
      if (!isThisCurrentPLan) {
        (userPlan?.plan == 'free') ? Navigate(`/checkout?plan=${plan.header}&type=${plantype}`) : setCheckout(true, plantype, plan.header);
      }

    } catch (err) {
      console.log({ err })
    }
  }

  return (
    <dvi className="flex w-full flex-col space-y-4 py-4 pb-[5rem]">
      <div className='font-medium text-xs text-blue-800 bg-blue-100 rounded-lg mb-3 px-4 py-2 w-fit mx-auto'>Save upto 20% in yearly plans </div>
      <div className='  mx-auto flex space-x-2 cursor-pointer bg-gray-200 rounded-md'>
        {
          PlanDuration.map((i, k) => <span
            key={k}
            className={`bg p-2 text-[12px] capitalize 
          ${plantype === i.name ? "bg-primary text-white rounded-md" : ""} 
          `}
            onClick={() => setPlanType(i.name)}
          >{i.name}</span>)
        }
      </div>

      <div className='flex w-full flex-wrap gap-xl mx-auto  justify-center pb-3'>
        {

          currentPLanType.map((plan, k) => {
            const isThisCurrentPLan = (userPlan?.plan == plan.header && userPlan?.type == plantype)
            return (
              <div key={k} className='border-2 rounded-xl w-[30%] flex flex-col space-y-4  hover:border-primary'>
                <span className='text-center w-full  block pt-4 capitalize'>{plan.header}</span>
                <h3 className='text-xl text-center text- '>
                  <span className='text-sm px-[2px]'>$</span>
                  {plan.amount}
                  <span className='text-sm px-[2px]'>/month</span>
                </h3>
                {/* <button>Change Plan</button> */}
                <div className='w-4/5 mx-auto  flex justify-center'>

                  <PrimaryButton text="Change Plans" backgroundColor={!isThisCurrentPLan ? "bg-primary" : "bg-white"} textcolor={!isThisCurrentPLan ? "text-white" : "text-black"} classNames="w-full border-1" OnClick={() => trigger(isThisCurrentPLan, plan) }
                  />  {/* setCheckout(true,plantype,plan.header) */}
                </div>
                <div className='w-[70%] mx-auto flex flex-col pb-4'>
                  {
                    plan.data.map((benefits, index) => <div className='flex items-center space-x-2 '>
                      <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.00033 0.666016C4.40033 0.666016 0.666992 4.39935 0.666992 8.99935C0.666992 13.5993 4.40033 17.3327 9.00033 17.3327C13.6003 17.3327 17.3337 13.5993 17.3337 8.99935C17.3337 4.39935 13.6003 0.666016 9.00033 0.666016ZM6.74199 12.5744L3.75033 9.58268C3.42533 9.25768 3.42533 8.73268 3.75033 8.40768C4.07533 8.08268 4.60033 8.08268 4.92533 8.40768L7.33366 10.8077L13.067 5.07435C13.392 4.74935 13.917 4.74935 14.242 5.07435C14.567 5.39935 14.567 5.92435 14.242 6.24935L7.91699 12.5744C7.60033 12.8994 7.06699 12.8994 6.74199 12.5744Z" fill="#49D57A"></path></svg>
                      </span>
                      <span>
                        {benefits}
                      </span>
                    </div>)
                  }

                </div>
              </div>
            )
          }
          )
        }
      </div>
    </dvi>
  )
}
