import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';


export const planCalculation = ( plan, type, coupon = '' ) =>  {

    return new Promise (async (resolve,reject)=>{
        let payload = { plan, type , coupon }

        if( coupon == "" &&  type === 'yearly') payload['coupon'] = "GZENGAGE20"
        if(  coupon == "" &&  type !== 'yearly' ) delete payload.coupon 

        axios.post(`/billing/Checkout`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const createUserPLan = ( plan, type, token , coupon = "") =>  {

    return new Promise (async (resolve,reject)=>{
        
        let payload = { plan, type , token, coupon }
        if(!token) reject('invalid Token')
        if( coupon == "" &&  type === 'yearly') payload['coupon'] = "GZENGAGE20"
        if(  coupon == "" &&  type !== 'yearly' ) delete payload.coupon 

        axios.post(`/billing/create`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
    })

}

export const createShopifyUserPLan = ( plan, type) =>  {

    return new Promise (async (resolve,reject)=>{
        
        axios.post(`/shopify/engage/charge/${type}/${plan}`, ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
    })

}

export const ActivateShopifyUserPLan = ( charge_id ) =>  {

    return new Promise (async (resolve,reject)=>{
        
        axios.put(`/shopify/engage/charge/confirm/${charge_id}`, ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
    })

}

export const updateUserPLan = ( plan, type, coupon ) =>  {

    return new Promise (async (resolve,reject)=>{

        let payload = { plan, type , coupon }

        if( coupon == "" &&  type === 'yearly') payload['coupon'] = "GZENGAGE20"
        if(  coupon == "" &&  type !== 'yearly' ) delete payload.coupon 


        axios.put(`/billing/planupgrade`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    })

}

export const DeleteUserPLan = () =>  {

    return new Promise (async (resolve,reject)=>{

        axios.delete(`/billing/cancelSubscription` ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    })

}

export const getUserPLan = () =>  {

    return new Promise (async (resolve,reject)=>{
        
        axios.get(`/billing/user`).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    })

}

export const getUserPaymentSession = ( plan, type ) =>  {

    return new Promise (async (resolve,reject)=>{
        
        axios.post(`/billing/session?plan=${plan}&type=${type}`).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    })

}

export const getUserPaymentSessionStatus = ( plan, type, session_id ) =>  {

    return new Promise (async (resolve,reject)=>{
        
        axios.post(`/billing/session/status?session_id=${session_id}&plan=${plan}&type=${type}`).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    })

}